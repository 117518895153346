@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Montserrat Variable', sans-serif;
}

textarea {
  field-sizing: content;
}